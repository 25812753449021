<template>
  <h4>Page not found</h4>
</template>

<script>
export default {
  name: "Error",
};
</script>

<style scoped></style>
